import { useParams } from 'react-router-dom'
import React, {useEffect, useState} from 'react'
import { getDataSet } from '../decks'
import CardContainer from '../components/CardContainer'

export default function ConversationStarter() {
  const {name} = useParams()
  const [data, setData] = useState(getDataSet(name))

  useEffect(() => {
    setData(getDataSet(name))
  }, [name])

  return (<CardContainer deck={data}/> )
}
