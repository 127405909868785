import {useHistory} from 'react-router-dom'
import React, {useCallback} from 'react'
import Cards from '../Cards/cards'
import {Deck} from '../../decks'

export default function CardContainer({ deck }: { deck: Deck | undefined}) {
  return (
    <div className='card-container'>
      { deck
        ? <>
          <h1 className='title'>{deck.title}</h1>
          { deck.description && <p className='title'>{deck.description}</p>}
          <Cards data={deck.cards}/>
        </>
        : <InvalidDataSet/>
      }
    </div>
  )
}

function InvalidDataSet () {
  const history = useHistory()
  const toHome = useCallback(() => history.push('/'), [history])
  return (
    <>
      <h1 className='title'>Invalid name</h1>
      <div onClick={toHome} className='orange-button'>Go back to home</div>
    </>
  )
}
