import React, {useCallback} from 'react'
import {useHistory, useParams} from 'react-router-dom'
import {Deck} from '../decks'
import CardContainer from '../components/CardContainer'
import {shuffleArray} from '../components/utils'

import crush from '../decks/conversation-starter-world/crush.json'
import askagirl from '../decks/conversation-starter-world/askagirl.json'
import askaguy from '../decks/conversation-starter-world/askaguy.json'
import fun from '../decks/conversation-starter-world/fun.json'
import overtext from '../decks/conversation-starter-world/overtext.json'
import personal from '../decks/conversation-starter-world/personal.json'
import ratherdeep from '../decks/conversation-starter-world/ratherdeep.json'
import ratherhard from '../decks/conversation-starter-world/ratherhard.json'
import thisorthat from '../decks/conversation-starter-world/thisorthat.json'


const deckList: Deck[] = [
  thisorthat,
  fun,
  overtext,
  personal,
  crush,
  askagirl,
  askaguy,
  ratherdeep,
  ratherhard,
]

export default function ConversationStartersWorldList() {
  const history = useHistory()
  const toPath = useCallback((path) => history.push(`${path}`), [history])

  return (
    <div className='card-container'>
      <p className='centered'>Decks I collected from Conversation Starters World</p>
      {
        deckList.map(deck => (
          <div
            key={deck.slug}
            onClick={() => toPath(`/conversation-starters-world/${deck.slug}`)}
            className='orange-button pointer'>{deck.title}</div>
        ))
      }
    </div>
  )
}

export function ConversationStartersWorld() {
  const {slug} = useParams()
  const deck = deckList.find(deck => deck.slug === slug)
  if (deck && deck.ordered !== true) deck.cards = shuffleArray(deck.cards)

  return <CardContainer deck={deck}/>
}

