import React, {useCallback} from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    useHistory, NavLink
} from "react-router-dom"
import './App.css'
import ConversationStarter from "./routes/ConversationStarter";
import Best250 from './routes/best250'
import ConversationStartersWorldList, {ConversationStartersWorld} from './routes/ConversationStartersWorldList'

export default function App() {
    return (
        <div className="App">
            <Router>
                    <ul className="navigation">
                        <li>
                            <NavLink to="/" exact activeClassName='active-link'>
                                Home
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/conversation-starters/korvonal" activeClassName='active-link'>
                                Korvonal
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/conversation-starters/bigtalks" activeClassName='active-link'>
                                Big Talks
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/conversation-starters-world" activeClassName='active-link'>
                                CWS
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/best250" activeClassName='active-link'>
                                CWS Best 250
                            </NavLink>
                        </li>
                    </ul>
                    {/* A <Switch> looks through its children <Route>s and
        renders the first one that matches the current URL. */}
                    <div className="container">
                        <Switch>
                            <Route path="/conversation-starters-world/:slug" children={<ConversationStartersWorld />} />
                            <Route path="/conversation-starters/:name" children={<ConversationStarter />} />
                            <Route path="/conversation-starters-world">
                                <ConversationStartersWorldList/>
                            </Route>
                            <Route path="/best250">
                                <Best250/>
                            </Route>
                            <Route path="/conversation-starters">
                                <Best250/>
                            </Route>
                            <Route path="/">
                                <Home/>
                            </Route>
                        </Switch>
                    </div>
            </Router>
        </div>
    );
}

function Home() {
    const history = useHistory()
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const toKorvonal = useCallback(() => history.push('/conversation-starters/korvonal'), [history])
    const toBigTalks = useCallback(() => history.push('/conversation-starters/bigtalks'), [history])

    return (<div className='home'>
        <div onClick={toKorvonal} className='idea'>
            <h3>Körvonal</h3>
            <p>Conversation starter based on the Hungarian Körvonal card game</p>
        </div>
        <div onClick={toBigTalks} className='idea'>
            <h3>Big Talks</h3>
            <p>Conversation starter based on the Big Talks card game</p>
        </div>
    </div>)

}
