import React, {useEffect, useState} from 'react'

import best250Json from '../decks/best250.json'
import Cards, { Card } from '../components/Cards/cards'
import {shuffleArray} from '../components/utils'

function getCards (tags: string[] = []) : Card[] {
  if (tags && tags.length > 0) {
    return best250Json
      .filter(deck => tags?.includes(deck.tag))
      .reduce<Card[]>((acc, curr) => {
        acc.push(...curr.cards.map(card => ({...card, tag: curr.tag})))
        return acc
      }, [])
  }

  return best250Json
    .reduce<Card[]>((acc, curr) => {
      acc.push(...curr.cards.map(card => ({...card, tag: curr.tag})))
      return acc
    }, [])
}

function getShuffledCards (tags: string[] = []) : Card[] {
  return shuffleArray(getCards(tags))
}

interface CheckedTypes {
  [name: string]: boolean
}

export default function Best250() {
  const initCheckedAll: CheckedTypes = {}
  const checkboxes = best250Json.map(deck => {
    initCheckedAll[deck.tag] = true
    return { name: deck.tag, label: deck.title, key: deck.tag}
  })

  const [checkedTypes, setCheckedTypes] = useState<CheckedTypes>(initCheckedAll)
  const [cards, setCards] = useState<Card[]>(getShuffledCards())

  function handleChange (event: any) {
    setCheckedTypes({...checkedTypes, [event.target.name]: event.target.checked})
  }

  useEffect(() => {
    console.log("checkedTypes: ", checkedTypes)
    const tags: string[] = []
    Object.entries(checkedTypes).forEach( ([key, value]) => {
      if (value) tags.push(key)
    })
    console.log(tags)
    setCards(getShuffledCards(tags))
  }, [checkedTypes])

  console.log(cards.length)
  return (
    <div className='deck-container'>
      <div className='deck-item11'>
        <h1 className='title'>CSW Best 250</h1>
      </div>
      <div className='deck-item2'>
        <Cards data={cards}/>
      </div>
      <div className='checkbox-container'>
        {
          checkboxes.map(item => (
            <label className='checkbox-with-label' key={item.key}>
              {item.name}
              <Checkbox name={item.name} checked={checkedTypes[item.name]} onChange={handleChange} />
            </label>
          ))
        }
      </div>
    </div>
  )
}

interface CheckboxProps {
  name: string,
  checked?: boolean,
  onChange(event: any): void
}

function Checkbox ({ name, onChange, checked = true} : CheckboxProps) {
  return (
    <label>
      <input type='checkbox' name={name} checked={checked} onChange={onChange} />
    </label>)
}
