import React, {useEffect, useState} from 'react';
import './cards.css'

export interface Card {
    text: string,
    description?: string,
    tag?: string
}

interface CardsProps {
    data: Card[]
}

export default function Cards(props: CardsProps) {
    const { data } = props

    const [index, setIndex] = useState(0)
    const [currentCard, setCurrentCard] = useState(data[0])
    const [showDescription, setShowDescription] = useState(false)

    useEffect(() => {
        setCurrentCard(data[0])
        setIndex(0)
        setShowDescription(false)
    }, [data])

    function nextCard () {
        let nextIndex = index + 1
        if (nextIndex === data.length) {
            nextIndex = 0
        }
        setIndex(nextIndex)
        setCurrentCard(data[nextIndex])
        setShowDescription(false)
    }

    console.log(`Current card: ${currentCard.text}`)
    return( <>
      <div className='card' onClick={nextCard}>
        <p className='text'>{currentCard.text}</p>
        {
            currentCard.description && showDescription &&
            <p className='description'>{currentCard.description}</p>
        }
      </div>
      {
          currentCard.description && !showDescription &&
          <p
            className='light-blue-button'
            onClick={() => setShowDescription(true)}>
              Show me description
          </p>
      }
    </>)
}


