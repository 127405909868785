import {shuffleArray} from "../components/utils"

import bigtalks from './bigtalks.json'
import korvonal from './korvonal.json'
import example from './exampleWithDescriptions.json'
import { Card } from '../components/Cards/cards'

export interface Deck {
    title: string,
    slug?: string,
    description?: string,
    tags?: string[],
    tag?: string,
    cards: Card[],
    ordered?: boolean
}

export interface Decks {
    [name: string]: Deck
}

const decks: Decks = {
    bigtalks,
    korvonal,
    example
}

export function getDataSet (name: string, shuffled = true) : Deck | undefined {
    const deck = decks[name]
    if (!deck) {
        console.error('Invalid dataset name', name)
        return undefined
    }

    if (shuffled) {
        deck.cards = shuffleArray(deck.cards)
    }

    return deck
}

