function getRandom (min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min)
}

export function shuffleArray(array) {
    const shuffled = array.slice(0)
    for (let i = shuffled.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
    }

    const r = getRandom(0, shuffled.length -1)
    const temp = shuffled[r]
    shuffled[r] = shuffled[0]
    shuffled[0] = temp

    return shuffled
}
